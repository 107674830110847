import { createRouter, createWebHistory } from "vue-router";
const basename = "";

const routes = [
  {
    path: "/",
    redirect: "/ologin",
  },
  {
    path: "/qrcode",
    name: "二维码展示",
    component: () => import(/* webpackChunkName: "newproj" */ "@/views/qrcode.vue"),
  },
  {
    path: "/bindpc",
    name: "二维码绑定-PC",
    component: () => import(/* webpackChunkName: "newproj" */ "@/views/bind/pc.vue"),
  },
  {
    path: "/bindtpad",
    name: "二维码绑定-平板",
    component: () => import(/* webpackChunkName: "newproj" */ "@/views/bind/teachpad.vue"),
  },
  {
    path: "/loginscreen",
    name: "大屏登录",
    component: () => import(/* webpackChunkName: "newproj" */ "@/views/login/screen.vue"),
  },
  {
    path: "/logintpad",
    name: "教师平板登录",
    component: () => import(/* webpackChunkName: "newproj" */ "@/views/login/teachpad.vue"),
  },
  {
    path: "/loginpc",
    name: "PC登录",
    component: () => import(/* webpackChunkName: "newproj" */ "@/views/login/pc.vue"),
  },

  {
    path: "/tpadlogin",
    name: "老平台扫码后登录-平板",
    component: () => import(/* webpackChunkName: "oldproj" */ "@/views/old/tpad/afterscan.vue"),
  },
  {
    path: "/tpadscan",
    name: "老平台扫码-平板",
    component: () => import(/* webpackChunkName: "oldproj" */ "@/views/old/tpad/code.vue"),
  },
  {
    path: "/codescan",
    name: "老平台扫码-大屏",
    component: () => import(/* webpackChunkName: "oldproj" */ "@/views/old/screen/code.vue"),
  },
  {
    path: "/ologin",
    name: "老平台扫码后登录-大屏",
    component: () => import(/* webpackChunkName: "oldproj" */ "@/views/old/screen/afterscan.vue"),
  }

];

const router = createRouter({
  history: createWebHistory(basename),
  routes,
});

export default router;
