<template>
  <div class="content">
    <router-view />
  </div>
</template>

<script setup>
</script>

<style>
</style>